@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Nunito+Sans:wght@700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300&display=swap);
html,
body {
  font-family: "Open Sans", sans-serif;
}
#hidden-title{
  display: none;
}

#nf-message{
  margin: 0;
  padding: 0.3rem 0.6rem;
  color: yellow;
}

h2 {
  font-size: 2.25rem;
  font-weight: 700;
  color: white;
}

input[type="checkbox"] {
  width: 12px;
  margin-right: 5px;
}

.pageBody {
  color: white;
  background-color: #3c3757;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#book-list{
  display: flex;
  justify-content: space-between;
}

#book-list .goBack-btn-wrapper{
  align-self: flex-end;
}

#goBack-btn{
  padding: 0.8rem 1.6rem;
  border-radius: 0.3rem;
  background-color: #9580ff;
  border: none;
  color:rgba(0, 0, 0);
  transition: background-color 0.2s ease-in-out;
}

#goBack-btn:hover {
  background-color: #04d361;
  color: white;
}

.cap{
  text-transform: capitalize;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  letter-spacing: 0.75px;
}

.book-size{
  width: 100px;
  height: 150px;
}

main {
  padding: 0.5rem 0;
  min-height: 100vh;
}
main section {
  width: 100%;
}
main section .lx-card {
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(34, 33, 44, 0.9);
}
main section .title {
  width: 100%;
  text-align: left;
  font-size: 1.15rem;
  font-family:'Nunito Sans', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.75px;
}
main section .title svg {
  color: #04d361;
  margin-right: 0.6rem;
}
main section .text {
  width: 100%;
  padding: 1rem;
  text-align: left;
  font-size: 0.88rem;
  border-radius: 0.328rem;
  background-color: rgba(0, 0, 0, 0.2);
}
main section .text svg {
  color: #ff5555;
}
main section .text ol {
  padding-inline-start: 2rem;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
main section .text p:first-child {
  margin-top: 0;
}
main section .text p:last-child {
  margin-bottom: 0;
  font-size: 1.05rem;
  font-weight: 100;
  letter-spacing: 0.5px;
  font-family: 'Barlow', sans-serif;

}
main section .pic {
  width: 100%;
  max-width: 15rem;
  max-height: 15rem;
  border-radius: 8rem;
  overflow: hidden;
}
main section .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
main section .infos {
  width: 100%;
  margin: 0.5rem 0 0 0;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  gap: 1rem;
  text-align: left;
  font-size: 0.875rem;
}
main section .infos svg {
  color: #04d361;
  font-size: 1.3rem;
}

main section .infos span{
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-family: 'Barlow', sans-serif;
  font-size: 1.0rem;
}
main section .mini-cards {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
main section .mini-cards a,
main section .mini-cards span {
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  border-radius: 0.328rem;
  font-size: 1rem;
  background-color: #2a2c37;
  transition: background-color 0.4s ease-in-out;
}
main section .mini-cards a svg,
main section .mini-cards span svg {
  color: #9580ff;
  transition: color 0.3s ease-in-out;
  font-size: 1.5rem;
}
main section .mini-cards a:focus,
main section .mini-cards a:hover,
main section .mini-cards span:focus,
main section .mini-cards span:hover {
  background-color: #04d361;
}
main section .mini-cards a:focus svg,
main section .mini-cards a:hover svg,
main section .mini-cards span:focus svg,
main section .mini-cards span:hover svg {
  color: #22212c;
}

#fav-book-list{
  display: flex;
  flex-direction: column;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

@media screen and (max-width: 65rem) {
  main section .mini-cards {
    grid-template-rows: 1fr 1fr;
  }
  .lx-row{
    grid-gap: 0;
    gap: 0;
  }
  #book-list .goBack-btn-wrapper{
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 901px){
  main section .mini-cards a, 
  main section .mini-cards span {
  height: auto;
  }
}


@media screen and (max-width: 50rem) {
  main section .mini-cards {
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 30rem){
  main section .mini-cards a, 
  main section .mini-cards span {
  height: auto;
  }
  .lx-row > .mini-cards{
    grid-template-rows: 1fr 1fr;
  }
}
:root {
  font-size: 16px;
  line-height: 1.5;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans, sans-serif";
  font-weight: 500;
}

h1 {
  font-size: 2.25rem;
  font-weight: 700;
}

h2.create-heading {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: #6d626d;
}

a {
  text-decoration: none;
  color: var(--primary-blue);
}

a:hover {
  text-decoration: underline;
}

.small {
  font-size: 80%;
  text-align: center;
}

.split-screen {
  display: flex;
  flex-direction: column;
}

.left {
  height: 200px;
  display: flex;
  flex-direction: column;
}

.left,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  background-color: #3c3757;
  ;
}

.left .copy {
  color: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.right .copy p {
  margin: 1.5em 0;
  font-size: 0.875rem;
}

.right form {
  width: 21rem;
}

.directions {
  color:#9c9c9c;
}

form input[type="text"] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 1em;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}

form input[type="checkbox"] {
  margin-right: 10px;
}

form div:first-child {
  grid-gap: 0.5rem;
}

.btn-wrapper{
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.signup-btn, #user-management button{
  background-color:rgb(88 176 59);
  color: white;
  border: none;
}

.signup-btn:hover,  #user-management button:hover{
  background-color: rgb(160, 52, 181);
  transition: background-color ease-in-out 0.4s;
}

#user-management{
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  height: 2rem;
}
#user-management input{
  width: 7rem;
  border:1px solid #807e85;
}
#user-management button{
  padding: 0;
  width: 7rem;
}

@media screen and (min-width: 900px) {
  .split-screen {
    flex-direction: row;
    height: 100vh;
  }
  .left,
  .right {
    display: flex;
    width: 50%;
    height: auto;
  }
}

.checkboxes {
  columns: 3 auto;
  column-rule: none;
  width: 100%;
  margin-bottom: 2rem;
}

.myLabel {
  display: inline-block;
  width: 213px;
  font-weight: 300;
}

@media screen and (max-width: 20rem){
  .right form{
    width: 100%;
    padding: 0 1rem 1rem;
  }
}
