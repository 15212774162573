@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300&display=swap');

:root {
  font-size: 16px;
  line-height: 1.5;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans, sans-serif";
  font-weight: 500;
}

h1 {
  font-size: 2.25rem;
  font-weight: 700;
}

h2.create-heading {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: #6d626d;
}

a {
  text-decoration: none;
  color: var(--primary-blue);
}

a:hover {
  text-decoration: underline;
}

.small {
  font-size: 80%;
  text-align: center;
}

.split-screen {
  display: flex;
  flex-direction: column;
}

.left {
  height: 200px;
  display: flex;
  flex-direction: column;
}

.left,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  background-color: #3c3757;
  ;
}

.left .copy {
  color: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.right .copy p {
  margin: 1.5em 0;
  font-size: 0.875rem;
}

.right form {
  width: 21rem;
}

.directions {
  color:#9c9c9c;
}

form input[type="text"] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 1em;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}

form input[type="checkbox"] {
  margin-right: 10px;
}

form div:first-child {
  grid-gap: 0.5rem;
}

.btn-wrapper{
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.signup-btn, #user-management button{
  background-color:rgb(88 176 59);
  color: white;
  border: none;
}

.signup-btn:hover,  #user-management button:hover{
  background-color: rgb(160, 52, 181);
  transition: background-color ease-in-out 0.4s;
}

#user-management{
  display: flex;
  justify-content: center;
  gap: 1rem;
  height: 2rem;
}
#user-management input{
  width: 7rem;
  border:1px solid #807e85;
}
#user-management button{
  padding: 0;
  width: 7rem;
}

@media screen and (min-width: 900px) {
  .split-screen {
    flex-direction: row;
    height: 100vh;
  }
  .left,
  .right {
    display: flex;
    width: 50%;
    height: auto;
  }
}

.checkboxes {
  columns: 3 auto;
  column-rule: none;
  width: 100%;
  margin-bottom: 2rem;
}

.myLabel {
  display: inline-block;
  width: 213px;
  font-weight: 300;
}

@media screen and (max-width: 20rem){
  .right form{
    width: 100%;
    padding: 0 1rem 1rem;
  }
}